import { APP_INITIALIZER, ApplicationConfig, Provider, importProvidersFrom } from "@angular/core";
import { provideRouter, withComponentInputBinding } from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { LoaderAndToasterInterceptor } from "@core/interceptors/loader-toaster.interceptor";
import { InterceptedHttp } from "@core/interceptors/http.interceptor";
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { NgbActiveModal, NgbModal, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "@env/environment";
import { MixpanelService } from "@core/services/mixpanel.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";

const initializeKeycloak = (keycloak: KeycloakService) => async () =>
  keycloak.init({
    config: environment.keycloak,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: window.location.origin + "/assets/silent-check-sso.html",
      checkLoginIframe: false,
      redirectUri: window.location.origin + "/dashboard",
    },
  });

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),

    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: LoaderAndToasterInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptedHttp, multi: true },

    importProvidersFrom(HttpClientModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(NgxSpinnerModule),
    provideAnimations(),
    provideClientHydration(),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptor,
    KeycloakService,
    NgbModalModule,
    NgbActiveModal,
    NgbModal,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    importProvidersFrom(KeycloakAngularModule),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      ToastrModule.forRoot({
        timeOut: 5000,
        extendedTimeOut: 5000,
        positionClass: "toast-top-right",
        tapToDismiss: false,
        preventDuplicates: true,
        titleClass: "toast-title",
      })
    ),
    MixpanelService,
    importProvidersFrom(BsDatepickerModule.forRoot()),
    importProvidersFrom(ModalModule.forRoot()),
  ],
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
