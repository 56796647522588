import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MixpanelService } from "@core/services/mixpanel.service";
import { HeaderService } from "@core/utility-services/header.service";
import { LocalStorageService } from "@core/utility-services/local-storage.service";
import { SidebarToggleService } from "@core/utility-services/sidebar-toggle.service";
import { AuthService } from "@pages/auth/services";
import { KeycloakProfile } from "keycloak-js";
import { Seeker } from "./header.model";

@Component({
  selector: "poocho-header",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent implements OnInit {
  showDropdown: boolean = false;
  userProfileName: string = "";
  userEmail: string | undefined;
  isEmailVerified: boolean | undefined;
  buttonClicked: boolean = false;
  userDetailId: number | null = null;
  userRoles: string[];

  buttonText: string = "Start Earning Today!";
  currentRole: string = "seeker"; // Default role

  constructor(
    private sidebarToggleService: SidebarToggleService,
    private authService: AuthService,
    private router: Router,
    private mixpanelService: MixpanelService,
    private localStorageService: LocalStorageService,
    private headerService: HeaderService
  ) {}

  ngOnInit() {
    this.loadUserProfile();
    this.userRoles = this.authService.getRoles();
    this.updateButtonText();
  }

  loadUserProfile() {
    this.authService.loadUserProfile().then((profile: KeycloakProfile) => {
      const firstName = profile.firstName || "";
      const lastName = profile.lastName || "";
      this.userProfileName = lastName ? `${firstName} ${lastName}` : firstName;
      this.userEmail = profile.email || "";
      this.isEmailVerified = profile.emailVerified || false;

      if (profile.attributes && Array.isArray(profile.attributes["user_detail_id"])) {
        this.userDetailId = profile.attributes["user_detail_id"][0] || null;

        this.localStorageService.setItem("userDetailId", this.userDetailId);
      }
    });
  }

  toggleFlag() {
    this.mixpanelService.track("menu_dropdown_click");
    this.showDropdown = !this.showDropdown;
  }

  toggleSidebar() {
    this.mixpanelService.track("sidebar_toggle_click");
    this.sidebarToggleService.toggleSidebar();
  }

  logout(): void {
    this.mixpanelService.track("menu_logout_click");
    this.mixpanelService.logout();
    this.authService.logout();
    this.localStorageService.clear();
  }

  redirectToProfile() {
    this.mixpanelService.track("menu_profile_click");
    this.router.navigate(["/profile"]);
  }

  getInitials(): string {
    if (!this.userProfileName) {
      return "";
    }
    const names = this.userProfileName.split(" ");
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    } else {
      return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
    }
  }

  onClickLogo(): void {
    this.mixpanelService.track("sidebar_logo_click");
    this.router.navigate(["/dashboard"]);
  }

  updateButtonText() {
    const storedButtonText = this.localStorageService.getItem("switchRoleButtonText");
    const storedRole = this.localStorageService.getItem("currentRole");

    if (storedButtonText) {
      this.buttonText = String(storedButtonText);
      this.currentRole = String(storedRole || "seeker");
    } else {
      const hasStudentRole = this.userRoles.includes("role-web-s-student");
      const hasTeacherRole = this.userRoles.includes("role-web-p-teacher");

      if (hasStudentRole && hasTeacherRole) {
        this.buttonText = "Switch To Provider";
        this.currentRole = "seeker";
      } else if (hasStudentRole) {
        this.buttonText = "Start Earning Today!";
        this.currentRole = "seeker";
      }

      this.localStorageService.setItem("switchRoleButtonText", this.buttonText);
      this.localStorageService.setItem("currentRole", this.currentRole);
    }
  }

  switchView() {
    if (this.buttonText === "Start Earning Today!") {
      if (this.userDetailId !== null) {
        const seeker: Seeker = { userDetailId: this.userDetailId };
        this.headerService.switchToProvider(seeker).subscribe((response) => {
          this.buttonText = "Switch To Seeker";
          this.currentRole = "provider";
          this.router.navigate(["provider/dashboard"]);
          this.localStorageService.clear();
          location.reload();
        });
      }
    } else if (this.buttonText === "Switch To Provider") {
      this.buttonText = "Switch To Seeker";
      this.currentRole = "provider";
      this.router.navigate(["provider/dashboard"]);
    } else if (this.buttonText === "Switch To Seeker") {
      this.buttonText = "Switch To Provider";
      this.currentRole = "seeker";
      this.router.navigate(["seeker/dashboard"]);
    }

    this.localStorageService.setItem("switchRoleButtonText", this.buttonText);
    this.localStorageService.setItem("currentRole", this.currentRole);
  }
}
