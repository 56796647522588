import { USER_ROLES } from "@core/constants/user-roles.constants";

export interface SidebarItem {
  label: string;
  icon: string;
  route: string;
  roles: string[];
  eventName: string;
  id: string;
}

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    label: "Dashboard",
    icon: "bi-grid",
    route: "seeker/dashboard",
    roles: [USER_ROLES.SEEKER_ROLE],
    eventName: "dashboard",
    id: "dashboard-item",
  },
  {
    label: "Profile",
    icon: "bi-person",
    route: "seeker/profile",
    roles: [USER_ROLES.SEEKER_ROLE],
    eventName: "profile",
    id: "profile",
  },

  {
    label: "Dashboard",
    icon: "bi-grid",
    route: "provider/dashboard",
    roles: [USER_ROLES.PROVIDER_ROLE],
    eventName: "dashboard",
    id: "dashboard-item",
  },
  {
    label: "Profile",
    icon: "bi-person",
    route: "provider/profile",
    roles: [USER_ROLES.PROVIDER_ROLE],
    eventName: "provider_profile",
    id: "provider-profile",
  },
  {
    label: "Setup Calendar",
    icon: "bi-calendar",
    route: "setup-calendar",
    roles: [USER_ROLES.PROVIDER_ROLE],
    eventName: "setup_calendar",
    id: "setup_calendar",
  },
  {
    label: "Schedule a Session",
    icon: "bi-calendar-check",
    route: "schedule-session",
    roles: [USER_ROLES.SEEKER_ROLE],
    eventName: "schedule_session",
    id: "schedule_session",
  },
];
