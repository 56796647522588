<poocho-header></poocho-header>
<router-outlet></router-outlet>
<poocho-footer></poocho-footer>
<a
  id="scroll-top"
  (click)="scrollToTop()"
  [ngClass]="{ active: isScrolled }"
  class="scroll-top d-flex align-items-center justify-content-center"
  ><i class="bi bi-arrow-up-short"></i
></a>
