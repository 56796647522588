import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { MixpanelService } from "@core/services/mixpanel.service";
import { SidebarToggleService } from "@core/utility-services/sidebar-toggle.service";
import { TitleService } from "@core/utility-services/title.service";
import { AuthService } from "@pages/auth/services";
import { FooterComponent } from "@shared/components/secure/footer/footer.component";
import { HeaderComponent } from "@shared/components/secure/header/header.component";
import { SidebarComponent } from "@shared/components/secure/sidebar/sidebar.component";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "poocho-layout2",
  standalone: true,
  imports: [HeaderComponent, SidebarComponent, RouterOutlet, FooterComponent, CommonModule],
  templateUrl: "./layout2.component.html",
  styleUrl: "./layout2.component.scss",
})
export class Layout2Component implements OnInit {
  isSidebarOpen: boolean = false;
  private subscription: Subscription;
  title$!: Observable<string>;
  loggedInUser: any;

  constructor(
    private sidebarToggleService: SidebarToggleService,
    private titleService: TitleService,
    private authService: AuthService,
    private mixpanelService: MixpanelService
  ) {
    this.subscription = this.sidebarToggleService.isSidebarOpen$.subscribe((isOpen) => {
      this.isSidebarOpen = isOpen;
    });
  }

  ngOnInit(): void {
    this.title$ = this.titleService.getCurrentPageTitle$();
    this.authService.loadUserProfile().then((profile) => {
      this.mixpanelService.identify(profile.id!);

      this.mixpanelService.track("after_login_visit");

      const fullName = profile.firstName + (profile.lastName ? " " + profile.lastName : "");

      this.mixpanelService.setSuperProperties({
        name: fullName,
        email: profile.email,
        emailVerified: profile.emailVerified,
      });

      this.mixpanelService.setPeople({
        $name: fullName,
        $email: profile.email,
        $emailVerified: profile.emailVerified,
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
