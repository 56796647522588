import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { constants } from "@app/constant";
import { Seeker } from "@shared/components/secure/header/header.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor(private httpClient: HttpClient) {}

  getSeekerById(seekerId: number): Observable<any> {
    const url = `${constants.apiUrl.seeker.seekerById}/${seekerId}`;
    return this.httpClient.get<any>(url);
  }
  switchToProvider(seeker: Seeker): Observable<any> {
    const url = `${constants.apiUrl.provider.seekerToProvider}`;
    return this.httpClient.post<any>(url, seeker);
  }
}
