import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot } from "@angular/router";
import { environment } from "@env/environment";
import { AppService } from "../utility-services/app.service";
import { BehaviorSubject, Observable } from "rxjs";

/**
 * This service is responsible for setting the current page title by reading the title information from ActivatedRoutes
 */
@Injectable({ providedIn: "root" })
export class TitleService {
  currentTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private title: Title) {}

  setTitle(snapshot: ActivatedRouteSnapshot): void {
    let currentSnapshot = snapshot;
    let parentTitle = "";

    // Find the deepest child and accumulate parent titles
    while (currentSnapshot.children.length) {
      parentTitle = currentSnapshot.data["title"] || parentTitle;
      currentSnapshot = currentSnapshot.children[0];
    }

    // Extract title from the deepest child
    const title = currentSnapshot.data["title"];

    // Set the document title based on the extracted title
    if (title) {
      const pageTitle = parentTitle ? `${parentTitle} | ${title}` : `${environment.appName} | ${title}`;
      this.title.setTitle(pageTitle);
      this._setCurrentPageTitle(parentTitle, title);
    } else {
      this.title.setTitle(environment.appName);
    }
  }

  getCurrentPageTitle$(): Observable<string> {
    return this.currentTitleSubject.asObservable();
  }

  private _setCurrentPageTitle(parentTitle: string, title: string): void {
    this.currentTitleSubject.next(title ? title : parentTitle);
  }
}
