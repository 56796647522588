<div [ngClass]="{ 'toggle-sidebar': isSidebarOpen }">
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
      <ng-container *ngFor="let item of filteredSidebarItems">
        <li class="nav-item" *ngIf="hasRole(item.roles)">
          <a
            class="nav-link"
            [routerLink]="item.route"
            routerLinkActive="collapsed"
            (click)="sendMixpanelEvent(item.eventName)"
            id="{{ item.id }}"
          >
            <i class="bi" [ngClass]="item.icon"></i>
            <span>{{ item.label }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </aside>
</div>
