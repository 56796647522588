import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { MixpanelService } from "@core/services/mixpanel.service";
import { SidebarToggleService } from "@core/utility-services/sidebar-toggle.service";
import { AuthService } from "@pages/auth/services";
import { Subscription } from "rxjs";
import { SIDEBAR_ITEMS, SidebarItem } from "./sidebar-items";
import { USER_ROLES } from "@core/constants/user-roles.constants";
import { LocalStorageService } from "@core/utility-services/local-storage.service";

@Component({
  selector: "poocho-sidebar",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
})
export class SidebarComponent implements OnInit {
  isSidebarOpen: boolean = false;
  private subscription: Subscription;
  userRoles: string[];
  sidebarItems: SidebarItem[] = SIDEBAR_ITEMS;
  filteredSidebarItems: any;

  constructor(
    private sidebarToggleService: SidebarToggleService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.subscription = this.sidebarToggleService.isSidebarOpen$.subscribe((isOpen) => {
      this.isSidebarOpen = isOpen;
    });

    this.userRoles = this.authService.getRoles();
  }

  ngOnInit() {
    this.filterSidebarItems();
    this.router.events.subscribe(() => {
      this.filterSidebarItems();
    });
  }

  filterSidebarItems() {
    const currentRole = this.localStorageService.getItem("currentRole") || "seeker";
    if (currentRole === "seeker") {
      this.filteredSidebarItems = this.sidebarItems.filter((item) => item.roles.includes(USER_ROLES.SEEKER_ROLE));
    } else if (currentRole === "provider") {
      this.filteredSidebarItems = this.sidebarItems.filter((item) => item.roles.includes(USER_ROLES.PROVIDER_ROLE));
    }
  }

  sendMixpanelEvent(eventName: string): void {
    const event = eventName + "_sidebar_click";
    this.mixpanelService.track(event);
  }

  hasRole(roles: string[]): boolean {
    return roles.some((role) => this.userRoles.includes(role));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
