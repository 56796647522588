import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { SidebarToggleService } from "@core/utility-services/sidebar-toggle.service";
import { Subscription } from "rxjs";

@Component({
  selector: "poocho-footer",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  isSidebarOpen: boolean = false;
  private subscription: Subscription;

  constructor(private sidebarToggleService: SidebarToggleService) {
    this.subscription = this.sidebarToggleService.isSidebarOpen$.subscribe((isOpen) => {
      this.isSidebarOpen = isOpen;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
